import React, {useState, useRef, useEffect} from 'react';
import {useTheme} from 'library/hooks';
import {Button} from 'library/components';
import styled from 'styled-components';

const Wrapper = styled.div(props => `

    margin-bottom: ${props.theme.margin.medium};
`);

const Title = styled.div(props => `

    padding-bottom: ${props.theme.padding.medium};
    ${props.$error !== false && `color: ${props.theme.color.negative.primary}`}
`);

const Container = styled.div(props => `

    display: flex;
    height: ${props.theme.height.medium};
`);

const Selected = styled.div(props => `

    display: flex;
    align-items: center;
    border-top: 1px solid ${props.theme.borderColor.core.primary};
    border-bottom: 1px solid ${props.theme.borderColor.core.primary};
    border-left: 1px solid ${props.theme.borderColor.core.primary};
    padding: 0 ${props.theme.padding.small} 0 ${props.theme.padding.small};
    background: #fff;
    border-radius: ${props.theme.borderRadius.small} 0 0 ${props.theme.borderRadius.small};
`);

const Text = styled.span(props => `

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: ${props.theme.height.medium};
    line-height: ${props.theme.height.medium};
    ${props.$grayed === true && `color: gray`}
`);

const Input = styled.input(() => `

    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`);

const LabelButton = styled.label(props => `

    display: flex;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(${props.theme.backgroundColor.sub.primary}, ${props.theme.backgroundColor.sub.secondary});
    border: 1px solid ${props.theme.backgroundColor.sub.primary};
    color: ${props.theme.color.sub.primary};
    padding: 0 ${props.theme.padding.small} 0 ${props.theme.padding.small};
    white-space: nowrap;
    border-radius: 0 ${props.theme.borderRadius.small} ${props.theme.borderRadius.small} 0;

    &:hover { 

        background: linear-gradient(${props.theme.backgroundColor.positive.primary}, ${props.theme.backgroundColor.positive.secondary});
        border: 1px solid ${props.theme.backgroundColor.positive.primary};
    }
`);

const Icon = styled.i(props => `

    padding-right: ${props.theme.padding.small};
`);

export default function File(props){

    const [state, setState] = useState({filename: '', size: 0});
    const {theme} = useTheme();
    const inputRef = useRef(null);

    useEffect(init, []);

    function init(){

        if(props.filename !== undefined && props.filename !== null){

            setState({
                
                filename: props.filename,
                size: 0
            });
        }
    }

    if(props.name === undefined){

        throw new Error('Name is not set');
    }

    if(props.label === undefined){

        throw new Error('Label is not set');
    }

    if(props.tooltip === undefined){

        throw new Error('Tooltip is not set');
    }

    if(props.placeholder === undefined){

        throw new Error('Placeholder is not set');
    }

    function handle(e){

        if(e.target.files.length === 0){

            props.onChange({name: props.name, value: undefined});

            setState({
                
                filename: '',
                size: 0
            });
        }

        else {

            props.onChange({name: props.name, value: e.target.files[0]});

            setState({
                
                filename: e.target.files[0].name,
                size: e.target.files[0].size
            });
        }
    }

    function removeFile(e){

        e.preventDefault();

        setState({
            
            filename: '',
            size: 0
        });

        props.onChange({name: props.name, value: undefined});
        inputRef.current.value = '';
    }

    return (

        <Wrapper style={props.style}>

            <Title $error={props.error}>{props.label}:</Title>

            <Container>

                <Selected>

                    <Text $grayed={state.filename === '' ? true : false}>{state.filename === '' ? props.placeholder : state.filename}</Text>

                </Selected>

                <Input ref={inputRef} type="file" name={props.name} id={props.name} onChange={handle} />

                {state.filename === ''

                    ? <LabelButton htmlFor={props.name}><Icon className="fa fa-upload" aria-hidden="true" /> {props.tooltip}</LabelButton>
                    : <LabelButton onClick={removeFile}><Icon className="fa fa-trash" aria-hidden="true" /> {props.remove}</LabelButton>
                }

            </Container>

        </Wrapper>
    );
}

File.defaultProps = {

    error: false,
    remove: 'Remove file',
    tooltip: 'Choose file',
    placeholder: 'No file chosen...'
};
